import * as React from 'react';
import FilterPane from './filter-pane';
import MapPane from './map-pane';
import ConnectedVirtualLocationDetailPane from './virtual-location-detail-pane';
import ConnectedLocationDetailPane from './location-detail-pane';
import ListPane from './list-pane';
import SearchControls from './search-controls';
import { connectMapRefinement } from './connectors/connectMapRefinement';
function LocationSearchResultsImpl({ searchAsYouType, Layout, ListItem, Marker, translate, google, algoliaAnalytics, currentRefinement, refine }) {
    const { selectedLocation } = currentRefinement;
    const setLocation = React.useCallback((location) => {
        if (location && selectedLocation &&
            location.objectID == selectedLocation.id) {
            // They may be trying to double-click the marker to zoom in further.
            // Reset the currentPlace so we don't limit the bounds
            refine({
                ...currentRefinement,
                includeUserLocationInBounds: false,
            });
            return;
        }
        if (algoliaAnalytics && algoliaAnalytics.aa && algoliaAnalytics.sendAnalytics && location) {
            const hitInternal = location;
            algoliaAnalytics.aa('clickedObjectIDsAfterSearch', {
                index: algoliaAnalytics.indexName,
                eventName: 'clickLocation',
                queryID: hitInternal.__queryID,
                objectIDs: [hitInternal.objectID],
                positions: [hitInternal.__position]
            });
        }
        refine({
            ...currentRefinement,
            selectedLocation: location ? {
                id: location.objectID,
                title: location.title,
                lat: location._geoloc?.lat,
                lng: location._geoloc?.lng,
            } : null,
            includeUserLocationInBounds: true,
        });
    }, [selectedLocation]);
    return React.createElement(Layout, { onClearSelectedLocation: () => setLocation(null), 
        // useCallback preserves the SearchControls and FilterPane so it doesn't
        // unmount and delete the current filter state whenever we rerender.
        SearchControls: React.useCallback((props) => React.createElement(SearchControls, { searchAsYouType: searchAsYouType, ...props, google: google }), [searchAsYouType, google]), FilterPane: React.useCallback(({ onFilterToggle }) => React.createElement(FilterPane, { translate: translate, onFilterToggle: onFilterToggle }), [translate]), SelectedLocationPane: React.useCallback(() => {
            if (!selectedLocation) {
                return null;
            }
            if (selectedLocation.isVirtual) {
                return React.createElement(ConnectedVirtualLocationDetailPane, { translate: translate });
            }
            return React.createElement(ConnectedLocationDetailPane, { translate: translate });
        }, [selectedLocation, translate]), ResultListPane: React.useCallback(() => React.createElement(ListPane, { google: google, onLocationSelect: setLocation, translate: translate, ListItem: ListItem }), [google, setLocation, selectedLocation, translate, ListItem]), Map: React.useCallback(() => React.createElement(MapPane, { google: google, loaded: true, onLocationSelect: setLocation, Marker: Marker }), [google, selectedLocation, setLocation, Marker]) });
}
export const LocationSearchResults = connectMapRefinement(LocationSearchResultsImpl);
